import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";

// Stylized console message
console.log(
  "%c Welcome to my Portfolio! 👋\n" +
    //     `
    // ██╗ ██████╗  █████╗ ███╗   ██╗    ███████╗
    // ██║██╔═══██╗██╔══██╗████╗  ██║    ╚══███╔╝
    // ██║██║   ██║███████║██╔██╗ ██║      ███╔╝
    // ██║██║   ██║██╔══██║██║╚██╗██║     ███╔╝
    // ██║╚██████╔╝██║  ██║██║ ╚████║    ███████╗
    // ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═══╝    ╚══════╝
    // ` +
    "%c ================================\n" +
    "%c 🚀 Full Stack Developer\n" +
    "%c 💻 React | Python | Node.js | TypeScript - you name it\n" +
    "%c 🔍 Looking for some source code? Check out my GitHub:\n" +
    "%c https://github.com/zioan\n" +
    "%c\n" +
    "%c\n" +
    "%c Interested in working together? Contact me at:\n" +
    "%c 📧 zaharia.ioan[at]gmail.com\n",
  "font-size: 20px; font-weight: bold; color: #60A5FA;",
  "font-size: 16px; color: #60A5FA;",
  "font-size: 16px; color: #34D399;",
  "font-size: 14px; color: #818CF8;",
  "font-size: 14px; color: #F472B6;",
  "font-size: 14px; color: #60A5FA; text-decoration: underline;",
  "font-size: 14px;",
  "font-size: 14px; background: #374151; color: #10B981; padding: 5px; border-radius: 4px;",
  "font-size: 14px;",
  "font-size: 14px; color: #F472B6;"
  // "font-size: 14px; color: #60A5FA;"
);

console.log("%c⚡ Page load time:", "color: #60A5FA", `${performance.now().toFixed(2)}ms`);

console.log("\nNice to see you checking under the hood!", "\nI know, it's not what you where looking for...🕵️");

console.log(
  "\n%cIf you find something wrong after this message, please let me know.\nWho knows what Chrome might decide to implement in the future... 🤔",
  "color: #9CA3AF; font-style: italic;" // Subtle gray, italicized
);

// End of fun, let's render the app
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
